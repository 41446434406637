
  import { Component, Vue } from "vue-property-decorator";
  import { dispatchAddNotification } from "@/store/main/actions";
  import CourseCard from "@/components/CourseCard.vue";
  import { ICheckoutSessionWithEvent, IEvent } from "@/interfaces";
  import { api } from "@/api";
  import { readToken } from "@/store/main/getters";
  import { formatMoney } from "@/utils";
  import { formatDate, formatTimeRange } from "@/date-utils";

  @Component({
    components: {
      CourseCard,
    },
  })
  export default class PurchaseHistory extends Vue {
    checkoutSessions: ICheckoutSessionWithEvent[] = [];

    public async mounted() {
      const token = readToken(this.$store);
      try {
        const resp = await api.getPurchases(token);
        this.checkoutSessions = resp.data;
      } catch {
        dispatchAddNotification(this.$store, {
          content: "Failed to get purchase history",
          color: "error",
        });
      }
    }

    formatMoney(amount: number | null) {
      return formatMoney(amount);
    }

    formatDate(dateString: string, utc_offset: string | null) {
      return formatDate(dateString, utc_offset);
    }

    formatEventTime(event: IEvent) {
      if (event) {
        return formatTimeRange(event.start_date, event.end_date, event.utc_offset);
      }
      return "";
    }
  }
